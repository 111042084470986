/*
PROJECT */
.project h2 {
  margin-bottom: 5px;
}
.project .bg-dotted {
  min-height: 100px;
  margin-bottom: -50px;
}
.project {
  margin-bottom: 80px;
}

.project .card-wide {
  display: flex;
}

.project .card-wide .left {
  flex-basis: 30%;
}
.project .card-wide .right {
  flex-basis: 70%;
}
/* TITLE */
.project .box-t {
  font-size: 20px;
  font-weight: 900;
  display: flex;
  justify-content: flex-end;
  flex-basis: 70%;
}
.project .box-t * {
  margin-left: 20px;
  padding: 0;
}
.project .box-t a {
  color: var(--heading);
  /* border-top: 2px solid black; */
  text-decoration: none;
  margin: 0;
  padding: 0;
}
.project .box-t a span {
  margin: 0;
  padding: 0;
  font-weight: 900;
}
.project .box-t a:hover {
  border-bottom: 2px solid var(--pink);
  color: var(--pink);
}

.project .box-t-container {
  display: flex;
  background-color: rgb(240, 242, 243);
  padding: 20px 20px;
  box-shadow: 4px -5px 50px 3px rgba(7, 48, 77, 0.14);
  -webkit-box-shadow: 4px -5px 50px 3px rgba(7, 48, 77, 0.14);
  -moz-box-shadow: 4px -5px 50px 3px rgba(7, 48, 77, 0.14);
}
@media screen and (max-width: 800px) {
  .project .box-t-container {
    flex-direction: column;
  }
  .project .box-t {
    justify-content: flex-start;
  }
  .project .box-t div {
    margin: 0;
    margin-right: 20px;
    margin-top: 10px;
  }
}

.project .project-slur h2 {
  flex-basis: 30%;
  font-size: 40px;
  font-weight: 900;
  margin: 0;
  margin-top: -10px;
  padding: 0;
}
.project .project-slur {
  flex-basis: 30%;
}
.project .box-ts {
  flex-basis: 70%;
}
.project .project-desc {
  padding-left: 30px;
  text-align: justify;
  font-size: 13px;
  padding-top: 10px;
}
@media screen and (max-width: 800px) {
  .project .project-desc {
    padding-left: 0;
  }
}
.project .project-desc p {
  margin: 0;
  padding: 0;
}

.project .red-bg-shadow {
  box-shadow: 7px 5px 18px 3px #0000005d;
}

.project .show-site {
  height: 100px;
  margin: 30px;
  padding: 50px;
  display: block;
}

.project .display {
  display: flex;
  gap: 30px;
  padding: 30px;
  justify-content: space-evenly;
  justify-content: flex-end;
  background-color: rgb(19, 20, 26);
}

.project .display a {
  flex-basis: 33%;
}
.project .display a img {
  max-height: 300px;
}

.badge {
  box-sizing: content-box;
  position: relative;
  width: 50px;
  padding: 0px 5px;
  top: 8px;
  display: inline-block;
}
.project p {
  font-size: 1rem;
}

.smallProject h2 {
  font-size: 1.75rem;
  padding-bottom: 0;
  margin-bottom: 0;
}
.smallProject h3 a {
  font-weight: 600;
  text-decoration: none;
}

.small-project-shadow {
  box-shadow: 6px 10px 23px 0px rgba(0, 32, 79, 0.24);
  -webkit-box-shadow: 6px 10px 23px 0px rgba(0, 32, 79, 0.24);
  -moz-box-shadow: 6px 10px 23px 0px rgba(0, 32, 79, 0.24);
}


/* Credits go to: Tobias Ahlin Bjerrome,
https://tobiasahlin.com/blog/how-to-animate-box-shadow/ */
.super-shadow {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.super-shadow::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.super-shadow:hover {
  -webkit-transform: scale(1.25, 1.25);
  transform: scale(1.25, 1.25);
}

.super-shadow:hover::after {
    opacity: 1;
}