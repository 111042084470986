.hero {
    display: flex;
    align-items: stretch;
    min-height: calc(100vh - 420px);
  }
  .hero-contact {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 20px;
    font-weight: 600;
  }
  .letsTalkWrapper{
    display: flex;
    justify-content: center;
  }
  .letsTalkWrapper:hover .heroLinkedInTeal{
    left:-7px !important;
    transform: rotate(-35deg);
    animation: none;
  }
  .heroLinkedInTeal{
    position:relative;
    top:-8px;
    max-width: 25px;
    margin-right:10px;
    animation-duration: 1.4s;
    animation-name: linkedin;
    animation-iteration-count: infinite;
  }

  @keyframes linkedin {
    0%   { left:0px; top:-7px;transform: rotate(5deg)}
    10%  { left:2px; top:-7px;transform: rotate(15deg)}
    30%  { left:5px; top:-9px; transform: rotate(40deg)}
    50%  { left:4px; top:-7px; transform: rotate(35deg)}
    60%  { left:-1px; top:-7px; transform:rotate(0deg)}
    80%  { left:-5px; top:-7px; transform:rotate(-15deg)}
    80%  { left:-2px; top:-7px; transform:rotate(-35deg)}
    100%  { left:0px; top:-7px;transform: rotate(5deg)}
  }

  h1,
  .lets-talk {
    color: var(--heading);
    white-space: nowrap;
    font-family: "Playfair Display", serif;
    margin-top:0;
    font-size: 18px;
    font-weight: 700;
    text-shadow: 3px 4px 8px rgba(0, 33, 85, 0.22),
      2px 2px 0 hsl(0deg 0% 100% / 94%), 1px 2px 0 hsl(0deg 0% 100% / 94%),
      0 2px 0 hsl(0deg 0% 100% / 94%), -1px 2px 0 hsl(0deg 0% 100% / 94%),
      -1px -1px 0 hsl(0deg 0% 100% / 94%), -2px -1px 0 hsl(0deg 0% 100% / 94%),
      -2px -2px 0 hsl(0deg 0% 100% / 94%);
  }

  .lets-talk {
    border-bottom: transparent 3px solid;
    color: var(--teal);
  }
  .lets-talk:hover {
    color: var(--pink);
    border-bottom: var(--pink) 3px solid;
  }

  .bg-dotted {
    background-image: url("../img/bg-img-repeat.png");
    background-repeat: repeat;
    background-size: 15px 15px;
  }

  .bg-white {
    background: var(--bgWhite);
  }
  .sunset-cover {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    min-height: 300px;
    width: 100%;
  }
  .sunset {
    position: relative;
    bottom: -10px;
    max-width: 400px;
  }
  .boxhang {
    min-height: 100px;
    padding-bottom: 20px;
    padding-top: 10px;
  }

  @media only screen and (max-width: 600px) {
    .sunset {
      max-width: 100%;
    }
    .h1 {
      font-family: "Playfair Display", serif;
      font-size: 45px;
      font-weight: 600;
      max-width: 90%;
    }
  }


  .container-narrow {
    max-width: 650px;
    margin: 0 auto;
    padding: 0 20px;
  }