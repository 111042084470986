@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

* {
  box-sizing: border-box;
}
:root {
  --bgWhite: #fcfbfa;
  --bgColor: #0a0e1b;
  --linkColor: rgb(217 143 252);
  --linkHover: rgb(223 119 254);
  --pink: #a90095;
  --heading: #0a1b32;
  --teal: #018d96;
}
.dark-bg {
  background: var(--bgColor);
}

::selection {
  color: #001780;
  background: #f2cffd;
}

body {
  background: var(--bgWhite);
}
h1,
h2,
h3,
p,
body,
li {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: rgb(47 46 68);
}
p {
  font-size: 1.2em;
  line-height: 1.7;
  padding-bottom: 20px;
}
a {
  color: var(--teal);
}
a:hover {
  color: var(--pink);
  text-decoration: underline;
}

.h1 {
  font-family: "Playfair Display", serif;
  font-size: 65px;
  font-weight: 800;
  color: var(--heading);
  margin-bottom: 20px;
  color: rgb(24, 34, 50);
  padding-top: 10vh;

  text-shadow: 3px 4px 8px rgba(0, 33, 85, 0.22),
    2px 2px 0 hsl(0deg 0% 100% / 94%), 1px 2px 0 hsl(0deg 0% 100% / 94%),
    0 2px 0 hsl(0deg 0% 100% / 94%), -1px 2px 0 hsl(0deg 0% 100% / 94%),
    -1px -1px 0 hsl(0deg 0% 100% / 94%), -2px -1px 0 hsl(0deg 0% 100% / 94%),
    -2px -2px 0 hsl(0deg 0% 100% / 94%);
}
.delightful {
  color: rgb(6, 110, 136);
  border-bottom: 4px dotted rgb(6, 110, 136);
}
.text-white{
  color: var(--bgWhite);
}

h2 {
  font-family: "Playfair Display", serif;
  color: var(--heading);
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 600;
}
h2 strong {
  font-weight: 900;
  color: rgb(3, 3, 3);
  font-style: italic;
}
h3 {
  font-family: "Playfair Display", serif;
  color: var(--heading);
  font-size: 1.1em;
  margin-bottom: 20px;
  margin-top: 10px;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}
@media screen and (max-width: 1000px) {
  .container {
    padding: 0 20px;
  }
}
.center{
  margin:0 auto;
  display: block;
}

h3 a {
  font-style: italic;
  font-size: 1rem;
  color: #007e9e;
}
h3 a:hover {
  color: #00d0df;
  text-decoration: none;
  cursor: hand;
  cursor: zoom-in;
}
.img-resp {
  max-width: 100%;
  height: auto;
}
.text-center {
  text-align: center;
}
.center {
  margin: 0 auto;
}
.text-right {
  text-align: right;
}
.text-white {
  color: #f0f0f3;
}

.flex {
  display: flex;
}
.justify-end {
  justify-content: flex-end;
}

.basis20 {
  flex-basis: 20%;
}
.basis25 {
  flex-basis: 25%;
}
.basis30 {
  flex-basis: 33%;
}
.basis40 {
  flex-basis: 50%;
}
.basis60 {
  flex-basis: 60%;
}
.basis70 {
  flex-basis: 70%;
}
.basis50 {
  flex-basis: 50%;
}

@media only screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
  .basis50 {
    flex-basis: 100%;
  }
  .basis30 {
    flex-basis: 100%;
  }
  .basis20 {
    flex-basis: 100%;
  }
}

.pt-50 {
  padding-top: 50px;
}
.pt-40 {
  padding-top: 45px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pt-30 {
  padding-top: 30px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-20 {
  padding-bottom: 30px;
}
.pb-10 {
  padding-bottom: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30{
  margin-top: 30px;
}
.mt-50{
  margin-top: 50px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-50 {
  margin-bottom: 50px;
}

.tw-icon {
  box-sizing: content-box;
  position: relative;
  width: 30px;
  padding: 0px 5px;
  top: 8px;
  display: inline-block;
}
.tw-icon img {
  max-width: 100%;
  border-radius: 50%;
}

.container-narrow h2 {
  max-width: 450px;
  display: block;
}
.container-wide {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}
.w800 {
  max-width: 800px;
}
.w500 {
  max-width: 500px;
}
.w300 {
  max-width: 300px;
}
.w200 {
  max-width: 200px;
}

.section-space {
  padding-top: 30px;
  padding-bottom: 80px;
}

.red-shadow {
  -webkit-box-shadow: 7px 5px 18px 5px #023838;
  box-shadow: 5px 5px 28px 2px #47060e;
}

.teal-shadow {
  -webkit-box-shadow: 7px 5px 18px 5px #023838;
  box-shadow: 7px 5px 18px 5px #023838;
}
.lightBlueShadow {
  -webkit-box-shadow: 7px 5px 18px 5px #09206574;
  box-shadow: 7px 5px 18px 5px #01071a3f;
}

.flex {
  display: flex;
}
.justify-end {
  justify-content: flex-end;
}

.basis20 {
  flex-basis: 20%;
}
.basis30 {
  flex-basis: 33%;
}
.basis40 {
  flex-basis: 50%;
}
.basis60 {
  flex-basis: 60%;
}
.basis70 {
  flex-basis: 70%;
}
.basis50 {
  flex-basis: 50%;
}

@media only screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
  .basis50 {
    flex-basis: 100%;
  }
  .basis30 {
    flex-basis: 100%;
  }
  .basis20 {
    flex-basis: 100%;
  }
}

.pt-50 {
  padding-top: 50px;
}
.pt-40 {
  padding-top: 45px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pt-30 {
  padding-top: 30px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-20 {
  padding-bottom: 30px;
}

.pl-desktop {
  padding-left: 20px;
}
.pr-desktop {
  padding-right: 20px;
}
@media only screen and (max-width: 600px) {
  .pl-desktop {
    padding-left: 0px;
  }
  .pr-desktop {
    padding-right: 0px;
  }

}

.round {
  border-radius: 20px;
}
/* //

#CenterCentre

// */
.uie-preview {
  padding: 0 10px;
  /* background-color: lightpink; */
  padding-top: 20px;
  overflow: hidden;
}
@media only screen and (max-width: 800px) {
  .uie-preview {
    padding: 0 10px;
    /* background-color: lightpink; */
    padding-top: 50px;
  }
}
.uie-preview img {
  position: relative;
  top: 20px;
  transition: all 1s;
}
.uie-preview img:hover {
  position: relative;
  top: 5px;
  transition: all 0.2s;
}
@media only screen and (max-width: 800px) {
  .uie-preview {
    max-width: 300px;
    margin: 0 auto;
  }
}
.preview-shadow {
  -webkit-box-shadow: 0px 10px 15px -15px rgba(58, 78, 100, 0.5);
  box-shadow: 0px 10px 15px -15px rgba(58, 78, 100, 0.5);
  transition: all 1s;
}
.preview-shadow:hover {
  -webkit-box-shadow: 0px 15px 15px -15px rgba(58, 78, 100, 0.5);
  box-shadow: 0px 15px 15px -15px rgba(58, 78, 100, 0.5);
  transition: all 0.2s;
}

.dotted-headline h2{
  padding-left:20px;
}