footer ul li {
  list-style-type: none;
  margin: 10px 0px;
  font-size: 1.4em;
}
footer .icon{
  max-height: 30px;
  margin: 10px;
}

.footerLogos img{
    max-height: 30px;
}

footer a{
    text-decoration: none;
}
footer ul{
    padding-inline-start: 0px;
}

footer .letsTalk{
    font-size: 1.4em;
    padding-bottom: 30px;
    color:var(--bgWhite);
    display: block;
}
footer .letsTalk:hover{
    font-size: 1.4em;
    color:var(--pink);
}

footer h2 {
    font-size: 32px;
    color:var(--bgWhite);

}
.footerLogos a{
    text-decoration: none;
    position: relative;
}
.footerLogos a:hover{
    text-decoration: none;
    top:-3px;
    transition: all 0.3s ease-in-out;
}